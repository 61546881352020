
import { defineComponent, ref, nextTick, computed } from "vue";
import {
  IonSplitPane,
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonIcon,
  IonButton,
  IonButtons,
  IonAvatar,
  IonText,
  IonLabel,
  IonItem,
  IonTextarea,
  IonFooter,
  IonSlides,
  IonSlide,
  IonChip,
  IonSpinner,
  IonFab,
  IonFabButton,
  IonProgressBar,
  actionSheetController,
  modalController,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  alertController,
} from "@ionic/vue";
import {
  chevronBackOutline,
  informationCircleOutline,
  addCircleOutline,
  camera,
  arrowDownOutline,
  trash,
  personOutline,
  sendOutline,
  expandOutline,
} from "ionicons/icons";

import ChatMenu from "@/components/messages/ChatMenu.vue";
import PhotosShowModal from "@/components/PhotosShowModal.vue";
import TheChatProblem from "@/components/messages/TheChatProblem.vue";

import router from "@/router";
import {
  contactInfo,
  chatInfo,
  listOfMessages,
  loadingTheChat,
  errorOccured,
  blockedUser,
  sendMedia,
  mediaUploadProgress,
  sendTextMessage,
  sendingMessage,
  allChatPhotos,
  sendingMedia,
} from "@/components/messages";
import { fbDB } from "@/firebase";
import { user } from "@/auth";
import { loadBaseInfoByUid } from "@/components/profile/show";
import { useI18n } from "vue-i18n";
import { darkModeEnabled } from "@/settings/settings";

import { unblockContact } from "../contacts";

export default defineComponent({
  components: {
    TheChatProblem,
    IonSplitPane,
    ChatMenu,
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonIcon,
    IonSpinner,
    IonButton,
    IonProgressBar,
    IonChip,
    IonSlides,
    IonSlide,
    IonButtons,
    IonAvatar,
    IonFab,
    IonFabButton,
    IonText,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonList,
    IonLabel,
    IonItem,
    IonTextarea,
    IonFooter,
  },
  setup() {
    // TODO
    // const allowedUsers = ["3IzTgLkdV7ZwuKU4bPfEgrsTHGp2", "ByD2w0e6N3YTAHeBuL9Qw7Xa7oO2", "k4rbNyfy5qWB2fAz24iAQfyxaiH3"];
    // if ( !allowedUsers.includes(user.value.uid)) {
    //   router.replace("/laska");
    // }

    const { t } = useI18n({ useScope: "global" });
    const chatId = router.currentRoute.value.params.id;
    const myUid = user.value.uid;
    const menu = ref();
    const noMoreMessages = ref(false);
    errorOccured.value = false;
    mediaUploadProgress.value = false;
    const chatContent = ref();
    const showScrollToBottomButton = ref<boolean>(false);
    const enableScrollEvents = ref<boolean>(false);
    loadingTheChat.value = true;

    const scrollToBottom = () => {
      chatContent.value?.$el.scrollToBottom(400);
    };

    const backToMessages = () => {
      fbDB.ref(`/chats/${chatId}/messages`).off();
      router.replace("/messages");
    };


    const runMessagesListener = async () => {
      /** Turn of automatic listening of messages. */
      fbDB.ref(`/chats/${chatId}/messages`).off();
      fbDB
        .ref(`/chats/${chatId}/messages`)
        .orderByChild("timestamp")
        .startAfter(
          listOfMessages.value[listOfMessages.value.length - 1].timestamp
        )
        .on("child_added", async (messageSnap) => {
          const theChat = Object.assign({}, messageSnap.val());
          theChat["msgId"] = messageSnap.key;
          const messageDate = new Date(theChat["timestamp"]);
          theChat["timeParsed"] = {
            date: messageDate.getDate(),
            year: messageDate.getFullYear(),
            month: messageDate.getMonth() + 1,
            hour: messageDate.getHours(),
            minute: messageDate.getMinutes(),
          };
          listOfMessages.value.push(theChat);
          nextTick().then(() => {
            setTimeout(() => {
              scrollToBottom();
            }, 100);
          });
          fbDB
            .ref(`/users/${user.value.uid}/chats/${chatId}/lastMessage/seen`)
            .set(null)
            .catch((error) => {
              console.log(error);
            });
        });
    };

    /** ########## CHAT INIT ########## */
    const loadMessagesOfChat = async () => {
      loadingTheChat.value = true;
      listOfMessages.value = [];
      allChatPhotos.value = [];

      const chatInfoSnap = await fbDB
        .ref(`users/${user.value.uid}/chats/${chatId}`)
        .once("value");
      if (chatInfoSnap.val() == null) {
        errorOccured.value = true;
        loadingTheChat.value = false;
        return;
      } else {
        chatInfo.value = chatInfoSnap.val();
        chatInfo.value["chatId"] = chatId;
        if (chatInfoSnap.val().blocked) {
          blockedUser.value = true;
        } else {
          blockedUser.value = false;
        }
      }

      const { result, profile } = await loadBaseInfoByUid(
        chatInfoSnap.val().partnerUid
      );
      if (result == "fail" || !profile) {
        errorOccured.value = true;
        loadingTheChat.value = false;
        return;
      }
      contactInfo.value = profile;

      if (blockedUser.value) {
        loadingTheChat.value = false;
        return;
      }

      fbDB
        .ref(`/chats/${chatId}/messages`)
        .orderByChild("timestamp")
        .limitToLast(30)
        .once("value")
        .then((messagesSnap) => {
          for (const [key, value] of Object.entries(messagesSnap.val())) {
            const theChat: any = Object.assign({}, value);
            theChat["msgId"] = key;
            const messageDate = new Date(theChat["timestamp"]);
            theChat["timeParsed"] = {
              date: messageDate.getDate(),
              year: messageDate.getFullYear(),
              month: messageDate.getMonth() + 1,
              hour: messageDate.getHours(),
              minute: messageDate.getMinutes(),
            };
            listOfMessages.value.push(theChat);
          }
          runMessagesListener();
          fbDB
            .ref(`/users/${user.value.uid}/chats/${chatId}/lastMessage/seen`)
            .set(null);
          setTimeout(() => {
            loadingTheChat.value = false;
            setTimeout(() => {
              scrollToBottom();
              enableScrollEvents.value = true;
            }, 700);
          }, 300);
        });

      fbDB.ref(`/chats/${chatId}/media`).once("value", (photosSnap) => {
        if (photosSnap.val() != null) {
          for (const [key, value] of Object.entries(photosSnap.val())) {
            const photo = { msgID: key, ...Object(value) };
            allChatPhotos.value.unshift(photo);
          }
        }

        fbDB
          .ref(`/chats/${chatId}/media`)
          .orderByChild("timestamp")
          .startAfter(
            allChatPhotos.value[0]?.timestamp
          )
          .on("child_added", (photoSnap) => {
            if (photoSnap.val() != null) {
              const photo = {
                msgID: photoSnap.key,
                ...Object(photoSnap.val()),
              };
              allChatPhotos.value.unshift(photo);
            }
          });
      });
    };
    loadMessagesOfChat();

    const loadNextMessages = async (ev: CustomEvent) => {
      const listOfOlderMessages: Array<any> = [];
      fbDB
        .ref(`/chats/${chatId}/messages`)
        .orderByChild("timestamp")
        .limitToLast(20)
        .endBefore(listOfMessages.value[0].timestamp)
        .once("value")
        .then((messagesSnap) => {
          if (messagesSnap.val() == null) {
            noMoreMessages.value = true;
            return;
          }
          const ionicSroll: any = ev.target;
          ionicSroll.complete();
          for (const [key, value] of Object.entries(messagesSnap.val())) {
            const theChat: any = Object.assign({}, value);
            theChat["msgId"] = key;
            const messageDate = new Date(theChat["timestamp"]);
            theChat["timeParsed"] = {
              date: messageDate.getDate(),
              year: messageDate.getFullYear(),
              month: messageDate.getMonth() + 1,
              hour: messageDate.getHours(),
              minute: messageDate.getMinutes(),
            };
            listOfOlderMessages.push(theChat);
          }
          listOfMessages.value = [
            ...listOfOlderMessages,
            ...listOfMessages.value,
          ];
        });
    };

    const unblockContactLocal = async () => {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: t("contacts.unblock"),
        buttons: [
          {
            text: t("buttons.close"),
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: t("buttons.yes"),
            handler: async () => {
              await unblockContact(
                contactInfo.value.uid,
                chatInfo.value.chatId
              );
              blockedUser.value = false;
              loadMessagesOfChat();
            },
          },
        ],
      });
      return alert.present();
    };

    const countOfMessages = computed(() => {
      return listOfMessages.value.length;
    });

    /** ########## TEXT MESSAGE ########## */
    const textMessage = ref<string>("");

    /** ########## PHOTO & VIDEO MESSAGE ########## */
    const mediaData = ref<Array<{ [key: string]: any }>>([]);
    const input1 = ref();
    const mediaForm = ref();
    const selectMedia = () => {
      mediaForm.value.reset();
      input1.value.click();
    };
    const previewPhotoMessage = async (event: any) => {
      if (blockedUser.value) {
        return;
      }
      const previousMedia: Array<{ [key: string]: any }> = mediaData.value;
      const nextMedia: Array<{ [key: string]: any }> = [];
      mediaData.value = [];
  
      await nextTick();

      if (event != null) {
        // URL.revokeObjectURL(img1.value);
        console.log(event.target.files);
        const selectedFiles: [] = event.target.files;
        selectedFiles.forEach((item: any, index: number) => {
          if (item.type.match("image.*")) {
            nextMedia.push({
              type: "image",
              url: URL.createObjectURL(item),
              data: event.target.files[index],
            });
            // nextMedia.push(URL.createObjectURL(item));
          } else {
            nextMedia.push({
              type: "video",
              url: URL.createObjectURL(item),
              data: event.target.files[index],
            });
          }
        });
        // TODO: problem with adding new slides to already initialized slider
        setTimeout(() => {
          mediaData.value = previousMedia.concat(nextMedia);
        }, 200);

        // imageData.value = event.target.files[0];
        // img1.value = URL.createObjectURL(event.target.files[0]);
        // openPhotoMessageModal();
        // console.log(img1);
      }
    };
    const sliderOptionsMediaToSend: any = {
      slidesPerView: "2",
      grabCursor: true,
      spaceBetween: 10,
      breakpoints: {
        350: {
          slidesPerView: 2.5,
          spaceBetween: 5,
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 4,
          spaceBetween: 10,
        },
        800: {
          slidesPerView: 5,
          spaceBetween: 10,
        },
        // when window width is >= 640px
        1000: {
          slidesPerView: 6,
          spaceBetween: 10,
        },
        1200: {
          slidesPerView: 8,
          spaceBetween: 10,
        },
        1400: {
          slidesPerView: 10,
          spaceBetween: 10,
        },
        1600: {
          slidesPerView: 12,
          spaceBetween: 10,
        },
      },
    };
    const showMediaToSendOptions = async (index: number) => {
      const actionSheet = await actionSheetController.create({
        header: t("chat.mediumToSend"),
        cssClass: "",
        buttons: [
          {
            text: t("buttons.show"),
            icon: expandOutline,
            handler: async () => {
              const modal = await modalController.create({
                component: PhotosShowModal,
                cssClass: "photoModalClass",
                componentProps: {
                  photos: mediaData.value,
                  index: index,
                },
              });
              return modal.present();
            },
          },
          {
            text: t("buttons.delete"),
            role: "destructive",
            icon: trash,
            handler: () => {
              mediaData.value.splice(index, 1);
            },
          },
        ],
      });
      await actionSheet.present();

      await actionSheet.onDidDismiss();
    };

    const sendMessage = async () => {
      if (sendingMessage.value != true) {
        sendingMessage.value = true;
        if (mediaData.value.length != 0 && mediaUploadProgress.value != true) {
          mediaUploadProgress.value = true;
          sendMedia(mediaData.value).finally(() => {
            mediaData.value = [];
          });
        }
        await sendTextMessage(textMessage.value);
        textMessage.value = "";
        sendingMessage.value = false;
      }
    };

    const openMediaModal = async (msgID: string) => {
      const index = allChatPhotos.value.findIndex((p) => p.msgID === msgID);
      const photos = allChatPhotos.value;
      const modal = await modalController.create({
        component: PhotosShowModal,
        cssClass: "photoModalClass",
        componentProps: {
          photos: JSON.parse(JSON.stringify(photos)),
          index: JSON.parse(JSON.stringify(index)),
        },
      });
      return modal.present();
    };

    /** Check for difference between dates of two neighboring messages.
     * @returns true  if two messages hasn't been sent in same day.
     */
    const compareMessageTimestamps = (lastDate: any, currentDate: any) => {
      if (lastDate.year == currentDate.year) {
        if (lastDate.month == currentDate.month) {
          if (lastDate.date == currentDate.date) {
            return false;
          }
        }
      }
      return true;
    };

    const pasrseTime = (messageDate: any) => {
      return `${("0" + messageDate.hour.toString()).slice(-2)}:${(
        "0" + messageDate.minute.toString()
      ).slice(-2)}`;
    };

    const checkChatAreaForScroll = (event: any) => {
      const scrollMainArea = event.target.shadowRoot.querySelector("main");
      if (
        scrollMainArea.scrollTop + scrollMainArea.clientHeight + 300 <
        scrollMainArea.scrollHeight
      ) {
        showScrollToBottomButton.value = true;
      } else {
        showScrollToBottomButton.value = false;
      }
    };

    return {
      checkChatAreaForScroll,
      showScrollToBottomButton,
      enableScrollEvents,
      pasrseTime,
      compareMessageTimestamps,
      chatContent,
      unblockContactLocal,
      sendingMessage,
      sendingMedia,
      errorOccured,
      noMoreMessages,
      loadNextMessages,
      countOfMessages,
      scrollToBottom,
      darkModeEnabled,
      loadMessagesOfChat,
      blockedUser,
      openMediaModal,
      mediaForm,
      selectMedia,
      personOutline,
      sendMessage,
      showMediaToSendOptions,
      sliderOptionsMediaToSend,
      mediaUploadProgress,
      menu,
      previewPhotoMessage,
      input1,
      mediaData,
      // imageData,
      // loadData,
      myUid,
      textMessage,
      // sendTextMessageLocal,
      backToMessages,
      contactInfo,
      loadingTheChat,
      listOfMessages,
      chatInfo,
      // messages,
      camera,
      chevronBackOutline,
      arrowDownOutline,
      informationCircleOutline,
      addCircleOutline,
      sendOutline,
    };
  },
});
