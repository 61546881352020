<template>
  <ion-menu
    side="end"
    type="push"
    content-id="main-content"
    style="--width: 100%"
    :disabled="splitPaneDisabled"
  >
    <ion-header style="position: inherit">
      <ion-toolbar style="--border-style: none; --border-color: transparent">
        <ion-buttons slot="start">
          <ion-button @click="toogleChatSettings()">
            <ion-icon :icon="chevronBackOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div style="padding: 0.5rem">
        <div style="text-align: center">
          <ion-avatar
            style="
              background-color: grey;
              text-alighn: center;
              margin: auto;
              height: 150px;
              width: 150px;
            "
          >
            <div v-if="!contactInfo.profilePhoto" style="height: 100%">
              <ion-icon
                :icon="personOutline"
                size="large"
                style="height: 100%; color: ghostwhite"
              />
            </div>
            <img v-else :src="contactInfo.profilePhoto" />
          </ion-avatar>
          <h1 style="font-weight: bold; text-align: center">
            {{ contactInfo?.name }}
          </h1>
          <ion-chip @click="openProfileModal()" color="primary">
            <ion-icon :icon="personOutline"></ion-icon>
            <ion-label>{{ $t("chat.showProfile") }}</ion-label>
          </ion-chip>
        </div>
        <hr
          style="
            height: 1px;
            border-width: 0;
            color: gray;
            background-color: #eee;
            margin: 10px;
          "
        />

        <ion-item
          button
          @click="showSharedPhotos = !showSharedPhotos"
          detail="false"
          style="border-radius: 20px; margin-bottom: 0.5rem"
          :style="showSharedPhotos ? '--background: transparent' : ''"
        >
          <ion-label
            ><h2 style="font-weight: bolder">
              {{ $t("chat.sharedMedia") }}
            </h2></ion-label
          >
          <ion-icon
            v-if="showSharedPhotos"
            slot="end"
            :icon="chevronUpOutline"
          ></ion-icon>
          <ion-icon v-else slot="end" :icon="chevronDownOutline"></ion-icon>
        </ion-item>
        <div
          id="sharedMedia"
          v-if="showSharedPhotos && !blockedUser"
          style="margin-bottom: 2rem"
        >
          <ion-grid
            v-if="allChatPhotos.length != 0"
            style="max-height: 700px; overflow-y: scroll"
          >
            <ion-row>
              <ion-col
                @click="openSharedMediaModal(index)"
                size="4"
                v-for="(photo, index) in allChatPhotos"
                :key="photo.msgID"
                style=" height: 110px; padding: 0;"
              >
                <ion-img
                  v-if="photo.type == 'image'"
                  :src="photo.url"
                  class="sharedPhoto"
                  loading="lazy"
                />
                <video
                  v-else
                  style="
                    width: 100%;
                    padding:2px;
                    height: 110px;
                    cursor: pointer;
                    border-radius: 20px;
                    background: #ddd;
                    margin-bottom:5px;
                  "
                  poster="/assets/film-strip.svg"
                  muted
                >
                  <source :src="photo.url" type="video/mp4" />
                  problem
                </video>
              </ion-col>
            </ion-row>
          </ion-grid>
          <div v-else-if="!blockedUser" style="text-align: center">
            {{ $t("chat.noMediaYet") }}
          </div>
        </div>

        <ion-item
          button
          @click="showPrivacySettings = !showPrivacySettings"
          detail="false"
          style="border-radius: 20px; margin-bottom: 0.5rem"
          :style="showPrivacySettings ? '--background: transparent' : ''"
        >
          <ion-label
            ><h2 style="font-weight: bolder">
              {{ $t("chat.privacyAndSupport") }}
            </h2></ion-label
          >
          <ion-icon
            v-if="showPrivacySettings"
            slot="end"
            :icon="chevronUpOutline"
          ></ion-icon>
          <ion-icon v-else slot="end" :icon="chevronDownOutline"></ion-icon>
        </ion-item>
        <ion-list class="settingsList" v-if="showPrivacySettings">
          <ion-item button @click="blockContactLocal()">
            <ion-icon slot="start" :icon="banOutline" style=""></ion-icon>
            <ion-label>
              {{
                blockedUser
                  ? $t("chat.unblockContact")
                  : $t("chat.blockContact")
              }}
            </ion-label>
          </ion-item>
          <ion-item button @click="reportContactLocal()">
            <ion-icon slot="start" :icon="megaphoneOutline" style=""></ion-icon>
            <ion-label> {{ $t("chat.reportContact") }} </ion-label>
          </ion-item>
          <ion-item button @click="showReportGeneralModal()">
            <ion-icon slot="start" :icon="warningOutline" style=""></ion-icon>
            <ion-label> {{ $t("chat.reportProblem") }} </ion-label>
          </ion-item>
        </ion-list>
      </div>
    </ion-content>
  </ion-menu>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonIcon,
  IonButton,
  IonButtons,
  IonAvatar,
  IonImg,
  IonLabel,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonMenu,
  IonChip,
  modalController,
  menuController,
  isPlatform,
  alertController,
} from "@ionic/vue";
import {
  chevronBackOutline,
  informationCircleOutline,
  addCircleOutline,
  sendOutline,
  chevronDownOutline,
  chevronUpOutline,
  colorFilterOutline,
  megaphoneOutline,
  warningOutline,
  banOutline,
  colorPaletteOutline,
  personOutline,
} from "ionicons/icons";

import {
  contactInfo,
  chatInfo,
  loadingTheChat,
  allChatPhotos,
  blockedUser,
} from "@/components/messages";

import ProfileModal from "@/components/messages/ProfileModal.vue";
import PhotosShowModal from "@/components/PhotosShowModal.vue";
import { useI18n } from "vue-i18n";
import { blockContact, unblockContact } from "../contacts";
import { reportProblem } from "@/report";
import ReportModal from "@/report/ReportModal.vue";

export default defineComponent({
  components: {
    IonContent,
    IonHeader,
    IonToolbar,
    IonIcon,
    IonButton,
    IonButtons,
    IonAvatar,
    IonLabel,
    IonChip,
    IonList,
    IonItem,
    IonGrid,
    IonRow,
    IonCol,
    IonImg,
    IonMenu,
  },
  emits: ["loadChat"],
  setup(props, { emit }) {
    const { t } = useI18n({ useScope: "global" });
    const showSharedPhotos = ref<boolean>(false);
    const showPrivacySettings = ref<boolean>(false);
    const splitPaneDisabled = ref<boolean>(false);
    const toogleChatSettings = () => {
      const chatMenu = document.querySelector("ion-menu");
      if (!chatMenu) {
        return;
      }
      chatMenu.isActive().then((result) => {
        if (result == true) {
          menuController.toggle();
        } else if (result == false) {
          splitPaneDisabled.value = !splitPaneDisabled.value;
        }
      });
    };

    const openSharedMediaModal = async (index: number) => {
      const modal = await modalController.create({
        component: PhotosShowModal,
        cssClass: "photoModalClass",
        componentProps: {
          photos: allChatPhotos.value,
          index: index,
        },
      });
      return modal.present();
    };

    const openProfileModal = async () => {
      const modal = await modalController.create({
        component: ProfileModal,
        cssClass: isPlatform("desktop") ? "desktopModalClass" : "",
        componentProps: {
          uid: contactInfo.value.uid,
        },
      });
      return modal.present();
    };

    const blockContactLocal = async () => {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: blockedUser.value ? t("contacts.unblock") : t("contacts.block"),
        message: blockedUser.value ? "" : t("contacts.blockValidation"),
        buttons: [
          {
            text: t("buttons.close"),
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: t("buttons.yes"),
            handler: async () => {
              if (blockedUser.value) {
                blockedUser.value = false;
                await unblockContact(
                  contactInfo.value.uid,
                  chatInfo.value.chatId
                );
                emit("loadChat");
              } else {
                await blockContact(
                  contactInfo.value.uid,
                  chatInfo.value.chatId
                );
                blockedUser.value = true;
                loadingTheChat.value = false;
              }
            },
          },
        ],
      });
      return alert.present();
    };

    const reportContactLocal = async () => {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: t("contacts.report"),
        message: t("contacts.reportValidation"),
        inputs: [
          {
            name: "description",
            cssClass: "contactReportDescriptionArea",
            value: "",
            type: "textarea",
            placeholder: t("contacts.reportPlaceholder"),
          },
        ],
        buttons: [
          {
            text: t("buttons.close"),
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: t("buttons.report"),
            handler: (inputData) => {
              reportProblem({
                type: "contact",
                description: inputData.description.trim(),
              });
            },
          },
        ],
      });
      return alert.present();
    };

    const showReportGeneralModal = async () => {
      const modal = await modalController.create({
        component: ReportModal,
        cssClass: isPlatform("desktop") ? "registrationModalClass" : "",
        componentProps: {},
        swipeToClose: true,
      });
      return modal.present();
    };

    return {
      showReportGeneralModal,
      reportContactLocal,
      blockedUser,
      blockContactLocal,
      openProfileModal,
      openSharedMediaModal,
      showSharedPhotos,
      showPrivacySettings,
      allChatPhotos,
      toogleChatSettings,
      contactInfo,
      chatInfo,
      loadingTheChat,
      splitPaneDisabled,
      // Icons
      personOutline,
      megaphoneOutline,
      warningOutline,
      banOutline,
      chevronBackOutline,
      informationCircleOutline,
      addCircleOutline,
      sendOutline,
      chevronUpOutline,
      chevronDownOutline,
      colorFilterOutline,
      colorPaletteOutline,
    };
  },
});
</script>

<style scoped>
#sharedMedia::-webkit-scrollbar {
  display: none;
}

#sharedMedia {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.settingsList {
  background: transparent;
  padding-top: 0;
}

.settingsList ion-item {
  --background: transparent;
  border-radius: 20px;
}

ion-img.sharedPhoto {
  object-fit: cover;
  padding: 2px;
  height: 110px;
  cursor: pointer;
}

ion-img.sharedPhoto::part(image) {
  border-radius: 20px;
}
</style>