
import { defineComponent, ref } from "vue";
import {
  IonButton,
  IonButtons,
  IonToolbar,
  IonHeader,
  IonContent,
  IonPage,
  IonItem,
  IonTextarea,
  IonTitle,
  modalController,
} from "@ionic/vue";

import {reportProblem} from "@/report"; 

export default defineComponent({
  name: "Report",
  components: {
    IonButton,
    IonButtons,
    IonToolbar,
    IonHeader,
    IonContent,
    IonPage,
    IonItem,
    IonTextarea,
    IonTitle,
  },
  setup() {
    const closeModal = async () => {
      const modal = await modalController.getTop();
      if (modal) {
        modal.dismiss();
      }
    };

    const description = ref<string>("");
    const reportProblemLocal = () => {
      if (description.value.trim() != '') {
        reportProblem({description: description.value.trim(), type: "general"});
        closeModal();
      }
    }

    return {closeModal, description, reportProblemLocal};
  },
});
