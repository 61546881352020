<template>
  <ion-page>
   <ion-header>
      <ion-toolbar>
        <ion-title>{{$t("reports.reportProblem")}}</ion-title>
        <ion-buttons slot="end">
          <ion-button color="primary" @click="closeModal()">{{
            $t("buttons.close")
          }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{$t("reports.reportProblem")}}</ion-title>
        </ion-toolbar>
      </ion-header>
      <div>
          <ion-item style="border: 1px solid #ddd; border-radius: 20px; margin-top:1rem; padding: ">
            <ion-textarea v-model="description" maxlength="5000" minlength="1" rows="5"
              :placeholder="$t('reports.describeYourProblem')"
            ></ion-textarea>
          </ion-item>
           <ion-button
            @click="reportProblemLocal()"
            mode="ios"
            type="submit"
            expand="block"
            shape="round"
            color="primary"
            style="margin-top: 15px; height: 3rem"
          >
            {{$t("buttons.report")}}</ion-button
          >
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import {
  IonButton,
  IonButtons,
  IonToolbar,
  IonHeader,
  IonContent,
  IonPage,
  IonItem,
  IonTextarea,
  IonTitle,
  modalController,
} from "@ionic/vue";

import {reportProblem} from "@/report"; 

export default defineComponent({
  name: "Report",
  components: {
    IonButton,
    IonButtons,
    IonToolbar,
    IonHeader,
    IonContent,
    IonPage,
    IonItem,
    IonTextarea,
    IonTitle,
  },
  setup() {
    const closeModal = async () => {
      const modal = await modalController.getTop();
      if (modal) {
        modal.dismiss();
      }
    };

    const description = ref<string>("");
    const reportProblemLocal = () => {
      if (description.value.trim() != '') {
        reportProblem({description: description.value.trim(), type: "general"});
        closeModal();
      }
    }

    return {closeModal, description, reportProblemLocal};
  },
});
</script>

<style scoped>
ion-item {
  --background: inherit;
}
</style>