<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title v-if="profile">{{ profile.name }}</ion-title>
        <ion-buttons slot="end">
          <ion-button color="primary" @click="closeModal()">{{
            $t("buttons.close")
          }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content text-center class="ion-padding">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large" v-if="profile">{{ profile.name }}</ion-title>
        </ion-toolbar>
      </ion-header>
      <div v-if="loadingMyProfile">
        <ProfileSkeleton />
      </div>
      <div v-else>
        <div v-if="profile == null || errorOccured">
          nepodarilo sa nacitat profil :/
        </div>
        <div v-else>
          <ProfileHeder :profile="profile" :allPhotos="photos" />
          <ProfileBody :profile="profile" :allPhotos="photos" />
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  modalController,
} from "@ionic/vue";
import {
  personOutline,
  schoolOutline,
  locationOutline,
  briefcaseOutline,
  homeOutline,
  chevronBackOutline,
} from "ionicons/icons";
import { ref, defineComponent } from "vue";
import { loadProfileByUid } from "@/components/profile/show";

import ProfileHeder from "@/components/profile/show/ProfileHeader.vue";
import ProfileBody from "@/components/profile/show/ProfileBodyC.vue";
import ProfileSkeleton from "@/components/profile/show/ProfileSkeleton.vue";

export default defineComponent({
  name: "ProfileModal",
  components: {
    ProfileBody,
    ProfileHeder,
    ProfileSkeleton,
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonButton,
    IonButtons,
  },
  props: {
    uid: String,
  },
  setup(props: any) {
    const closeModal = async () => {
      const modal = await modalController.getTop();
      if (modal) {
        modal.dismiss();
      }
    };

    const profile = ref<object | null>({});
    const photos = ref<string[]>([]);
    const loadingMyProfile = ref(true);
    const errorOccured = ref(false);
    const loadMyProfileToShow = async () => {
      loadingMyProfile.value = true;
      try {
        const result = await loadProfileByUid(props.uid);
        if (result != null) {
          profile.value = result.profile;
          photos.value = result.allPhotos;
        } else {
          profile.value = null;
        }
      } catch (err) {
        errorOccured.value = true;
      } finally {
        loadingMyProfile.value = false;
      }
    };
    loadMyProfileToShow();

    return {
      closeModal,
      // REFERENCES
      // myProfile,
      loadingMyProfile,
      errorOccured,
      photos,
      profile,

      // FUNCTIONS

      // ICONS
      personOutline,
      schoolOutline,
      locationOutline,
      briefcaseOutline,
      homeOutline,
      chevronBackOutline,
    };
  },
});
</script>


<style scoped>
#titlePic::part(image) {
  border-radius: 30px;
  height: 180px;
  object-fit: cover;
  max-width: 700px;
  margin: auto;
}

.accountTabHeader {
  padding: 10px;
  margin-bottom: 30px;
  text-align: center;
}

.mainInfoCard {
  margin: 10px;
  padding: 10px;
  -webkit-box-shadow: 0 0 5px 2px #ddd;
  -moz-box-shadow: 0 0 5px 2px #ddd;
  box-shadow: 0 0 5px 2px #ddd;
  border-radius: 20px;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(8, 5vw);
  grid-gap: 15px;
}
</style>