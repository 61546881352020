<template>
  <ion-page>
    <ion-card
      style="
        text-align: center;
        border-radius: 25px;
        margin: auto;
        max-width: 400px;
        width: 80%;
      "
    >
      <ion-img
        src="/assets/logo.png"
        style="height: 50px; padding: 0.3rem"
      ></ion-img>
      <h2>{{$t("errors.problemOccured")}}<br /> {{$t("errors.chatNotFound")}}</h2>
      <ion-button @click="backToMessages()" mode="ios" shape="round"
        >{{$t("buttons.backToMessages")}}</ion-button
      >
      <p v-if="!alreadyReported" @click="reportChatProblem()">
        <span style="cursor: pointer">{{$t("reports.reportProblem")}}</span>
      </p>
    </ion-card>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { IonPage, IonCard, IonImg, IonButton } from "@ionic/vue";
import router from "@/router";
import { reportProblem } from "@/report";

export default defineComponent({
  components: {
    IonPage,
    IonCard,
    IonImg,
    IonButton,
  },
  setup() {
    const backToMessages = () => {
      router.replace("/messages");
    };
    const alreadyReported = ref(false);

    const reportChatProblem = () => {
      if (!alreadyReported.value) {
        reportProblem({
          chatId: router.currentRoute.value.params.id,
          type: "chat",
        });
      }
      alreadyReported.value = true;
    };

    return { backToMessages, reportChatProblem, alreadyReported };
  },
});
</script>