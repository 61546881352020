
import { defineComponent, ref } from "vue";
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonIcon,
  IonButton,
  IonButtons,
  IonAvatar,
  IonImg,
  IonLabel,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonMenu,
  IonChip,
  modalController,
  menuController,
  isPlatform,
  alertController,
} from "@ionic/vue";
import {
  chevronBackOutline,
  informationCircleOutline,
  addCircleOutline,
  sendOutline,
  chevronDownOutline,
  chevronUpOutline,
  colorFilterOutline,
  megaphoneOutline,
  warningOutline,
  banOutline,
  colorPaletteOutline,
  personOutline,
} from "ionicons/icons";

import {
  contactInfo,
  chatInfo,
  loadingTheChat,
  allChatPhotos,
  blockedUser,
} from "@/components/messages";

import ProfileModal from "@/components/messages/ProfileModal.vue";
import PhotosShowModal from "@/components/PhotosShowModal.vue";
import { useI18n } from "vue-i18n";
import { blockContact, unblockContact } from "../contacts";
import { reportProblem } from "@/report";
import ReportModal from "@/report/ReportModal.vue";

export default defineComponent({
  components: {
    IonContent,
    IonHeader,
    IonToolbar,
    IonIcon,
    IonButton,
    IonButtons,
    IonAvatar,
    IonLabel,
    IonChip,
    IonList,
    IonItem,
    IonGrid,
    IonRow,
    IonCol,
    IonImg,
    IonMenu,
  },
  emits: ["loadChat"],
  setup(props, { emit }) {
    const { t } = useI18n({ useScope: "global" });
    const showSharedPhotos = ref<boolean>(false);
    const showPrivacySettings = ref<boolean>(false);
    const splitPaneDisabled = ref<boolean>(false);
    const toogleChatSettings = () => {
      const chatMenu = document.querySelector("ion-menu");
      if (!chatMenu) {
        return;
      }
      chatMenu.isActive().then((result) => {
        if (result == true) {
          menuController.toggle();
        } else if (result == false) {
          splitPaneDisabled.value = !splitPaneDisabled.value;
        }
      });
    };

    const openSharedMediaModal = async (index: number) => {
      const modal = await modalController.create({
        component: PhotosShowModal,
        cssClass: "photoModalClass",
        componentProps: {
          photos: allChatPhotos.value,
          index: index,
        },
      });
      return modal.present();
    };

    const openProfileModal = async () => {
      const modal = await modalController.create({
        component: ProfileModal,
        cssClass: isPlatform("desktop") ? "desktopModalClass" : "",
        componentProps: {
          uid: contactInfo.value.uid,
        },
      });
      return modal.present();
    };

    const blockContactLocal = async () => {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: blockedUser.value ? t("contacts.unblock") : t("contacts.block"),
        message: blockedUser.value ? "" : t("contacts.blockValidation"),
        buttons: [
          {
            text: t("buttons.close"),
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: t("buttons.yes"),
            handler: async () => {
              if (blockedUser.value) {
                blockedUser.value = false;
                await unblockContact(
                  contactInfo.value.uid,
                  chatInfo.value.chatId
                );
                emit("loadChat");
              } else {
                await blockContact(
                  contactInfo.value.uid,
                  chatInfo.value.chatId
                );
                blockedUser.value = true;
                loadingTheChat.value = false;
              }
            },
          },
        ],
      });
      return alert.present();
    };

    const reportContactLocal = async () => {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: t("contacts.report"),
        message: t("contacts.reportValidation"),
        inputs: [
          {
            name: "description",
            cssClass: "contactReportDescriptionArea",
            value: "",
            type: "textarea",
            placeholder: t("contacts.reportPlaceholder"),
          },
        ],
        buttons: [
          {
            text: t("buttons.close"),
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: t("buttons.report"),
            handler: (inputData) => {
              reportProblem({
                type: "contact",
                description: inputData.description.trim(),
              });
            },
          },
        ],
      });
      return alert.present();
    };

    const showReportGeneralModal = async () => {
      const modal = await modalController.create({
        component: ReportModal,
        cssClass: isPlatform("desktop") ? "registrationModalClass" : "",
        componentProps: {},
        swipeToClose: true,
      });
      return modal.present();
    };

    return {
      showReportGeneralModal,
      reportContactLocal,
      blockedUser,
      blockContactLocal,
      openProfileModal,
      openSharedMediaModal,
      showSharedPhotos,
      showPrivacySettings,
      allChatPhotos,
      toogleChatSettings,
      contactInfo,
      chatInfo,
      loadingTheChat,
      splitPaneDisabled,
      // Icons
      personOutline,
      megaphoneOutline,
      warningOutline,
      banOutline,
      chevronBackOutline,
      informationCircleOutline,
      addCircleOutline,
      sendOutline,
      chevronUpOutline,
      chevronDownOutline,
      colorFilterOutline,
      colorPaletteOutline,
    };
  },
});
