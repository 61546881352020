<template>
  <div>
    <div v-if="errorOccured">
      <TheChatProblem />
    </div>
    <ion-split-pane v-else when="md" content-id="main-content">
      <ChatMenu ref="menu" v-on:load-chat="loadMessagesOfChat()" />
      <ion-page id="main-content">
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <ion-button @click="backToMessages()">
                <ion-icon :icon="chevronBackOutline"></ion-icon>
                <!-- <ion-badge slot="end">1</ion-badge> -->
              </ion-button>
            </ion-buttons>
            <ion-item
              lines="none"
              button
              detail="false"
              style="border-radius: 10px"
              @click="menu.toogleChatSettings()"
            >
              <ion-avatar
                style="background-color: grey; text-align: center"
                slot="start"
                id="partnerPhoto"
              >
                <div v-if="!contactInfo.profilePhoto" style="height: 100%">
                  <ion-icon
                    :icon="personOutline"
                    style="height: 100%; color: ghostwhite"
                  />
                </div>
                <img v-else :src="contactInfo.profilePhoto" />
              </ion-avatar>
              <ion-label class="ion-text-wrap">
                <ion-text color="primary">
                  <h2>{{ contactInfo.name }}</h2>
                </ion-text>
              </ion-label>
            </ion-item>
            <ion-buttons slot="end">
              <ion-button @click="menu.toogleChatSettings()">
                <ion-icon :icon="informationCircleOutline"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content v-if="loadingTheChat">
          <div
            id="overlay"
            :style="{ background: darkModeEnabled ? '#121212' : 'white' }"
          >
            <div id="text">
              <ion-spinner />
            </div>
          </div>
        </ion-content>
        <ion-content v-else-if="blockedUser">
          <div
            id="overlay"
            :style="{ background: darkModeEnabled ? '#121212' : 'white' }"
          >
            <div id="text">
              <h6>{{ $t("contacts.contactBlocked") }}</h6>
              <ion-chip @click="unblockContactLocal()" color="primary">
                <ion-label>{{ $t("contacts.unblock") }}</ion-label></ion-chip
              >
            </div>
          </div>
        </ion-content>
        <ion-content
          v-else
          id="chatContent"
          ref="chatContent"
          :scrollEvents="enableScrollEvents"
          @ionScrollEnd="checkChatAreaForScroll($event)"
        >
          <ion-infinite-scroll
            @ionInfinite="loadNextMessages($event)"
            threshold="100px;"
            id="infinite-scroll"
            position="top"
            :disabled="noMoreMessages ? true : false"
          >
            <ion-infinite-scroll-content loading-spinner="default">
            </ion-infinite-scroll-content>
          </ion-infinite-scroll>
          <ion-list style="scroll-behavior: smooth; background: transparent">
            <div
              v-for="(message, index) in listOfMessages"
              :key="index"
              style="display: flex; flex-direction: column"
              v-bind:style="
                message.sender == myUid
                  ? 'align-items: flex-end; '
                  : 'align-items: flex-start;'
              "
            >
              <div
                v-if="
                  index != 0 &&
                  compareMessageTimestamps(
                    listOfMessages[index - 1].timeParsed,
                    listOfMessages[index].timeParsed
                  )
                "
                style="width: 100%; text-align: center; margin: 1rem 0"
              >
                <ion-chip disabled style="height: 1.4rem">{{
                  message.timeParsed.date +
                  "." +
                  message.timeParsed.month +
                  "." +
                  message.timeParsed.year
                }}</ion-chip>
              </div>
              <ion-item
                v-if="message.type == 'text'"
                style="max-width: 85%"
                lines="none"
                class="messageItem"
              >
                <div
                  class="message"
                  v-bind:class="
                    message.sender == myUid ? 'messageMine' : 'messageYours'
                  "
                  style="overflow: auto; white-space: pre-line"
                >
                  <div style="display: inline-block; padding-right: 0.5rem">
                    <span>{{ message.text }}</span>
                  </div>
                  <div
                    style="
                      text-align: right;
                      font-size: xx-small;
                      opacity: 0.5;
                      display: inline-block;
                    "
                  >
                    {{ pasrseTime(message.timeParsed) }}
                  </div>
                </div>
              </ion-item>
              <ion-item v-else-if="message.type == 'image'" lines="none">
                <div
                  class=""
                  v-bind:style="
                    message.sender == myUid ? 'margin-left: auto;' : ''
                  "
                  style="border-radius: 20px; max-height: 300px; max-width: 60%"
                >
                  <img
                    @click="openMediaModal(message.msgId)"
                    style="
                      cursor: pointer;
                      border-radius: 20px;
                      max-height: 300px;
                      margin-bottom: 0.5rem;
                    "
                    :src="message.photoUrl"
                  />
                  <!-- <div>22:11</div> -->
                </div>
              </ion-item>
              <ion-item v-else-if="message.type == 'video'" lines="none">
                <div
                  v-bind:style="
                    message.sender == myUid ? 'margin-left: auto;' : ''
                  "
                  style="border-radius: 20px; max-height: 300px; max-width: 60%"
                >
                  <video
                    @click="openMediaModal(message.msgId)"
                    style="
                      width: 100%;
                      cursor: pointer;
                      border-radius: 20px;
                      background: #ddd;
                    "
                    poster="/assets/film-strip.svg"
                    controls
                    playsinline
                    muted
                  >
                    <source :src="message.photoUrl" type="video/mp4" />
                    problem
                  </video>
                </div>
              </ion-item>
              <div
                v-if="message.type == 'newContact'"
                style="
                  margin: auto;
                  text-align: center;
                  margin-top: 20px;
                  width: 80%;
                "
              >
                <ion-avatar
                  style="
                    -webkit-box-shadow: 0px 0px 8px 5px #fff;
                    -moz-box-shadow: 0px 0px 8px 5px #fff;
                    box-shadow: 0px 0px 8px 5px #fff;
                    background-color: #aaa;
                    position: relative;
                    margin: auto;
                    height: 150px;
                    width: 150px;
                  "
                >
                  <img
                    v-if="contactInfo.profilePhoto"
                    :src="contactInfo.profilePhoto"
                  />
                  <div
                    v-else
                    style="height: 100%; width: 100%; border-radius: 50%"
                  >
                    <ion-icon
                      size="large"
                      :icon="personOutline"
                      style="height: 100%; color: ghostwhite"
                    />
                  </div>
                </ion-avatar>
                <h1 style="font-weight: bold">{{ contactInfo.name }}</h1>
                <hr style="background: #ccc; margin: 0px 40px 10px 40px" />
              </div>
            </div>
          </ion-list>
          <transition name="fade" mode="out-in">
          <ion-fab v-show="showScrollToBottomButton" horizontal="center" vertical="bottom" slot="fixed">
            <ion-fab-button size="small" color="light" @click="scrollToBottom()">
              <ion-icon :icon="arrowDownOutline"></ion-icon>
            </ion-fab-button>
          </ion-fab>
          </transition>
        </ion-content>
        <ion-footer>
          <ion-progress-bar
            v-if="mediaUploadProgress"
            color="primary"
            type="indeterminate"
            :value="mediaUploadProgress"
          ></ion-progress-bar>
          <ion-toolbar style="padding: 5px 0px 5px 0px">
            <ion-buttons slot="start">
              <ion-button
                @click="selectMedia()"
                :disabled="blockedUser || sendingMedia"
              >
                <ion-icon :icon="camera"></ion-icon>
              </ion-button>
              <form ref="mediaForm">
                <input
                  id="input1"
                  ref="input1"
                  type="file"
                  accept="video/*,image/*"
                  style="display: none"
                  @change="previewPhotoMessage"
                  multiple
                />
              </form>
            </ion-buttons>
            <div style="display: block; max-height: 255px">
              <ion-slides
                v-if="mediaData.length != 0"
                :options="sliderOptionsMediaToSend"
                style="height: 100px"
              >
                <ion-slide
                  v-for="(media, index) in mediaData"
                  :key="index"
                  style="width: 90px; max-height: 100px"
                >
                  <div class="slide" style="max-height: 100px">
                    <img
                      v-if="media.type == 'image'"
                      @click="showMediaToSendOptions(index)"
                      loading="lazy"
                      style="
                        cursor: pointer;
                        object-fit: cover;
                        border-radius: 20px;
                        height: 90px;
                        width: 90px;
                      "
                      :src="media.url"
                    />
                    <video
                      v-else
                      @click="showMediaToSendOptions(index)"
                      style="
                        height: 90px;
                        width: 90px;
                        cursor: pointer;
                        object-fit: cover;
                        border-radius: 20px;
                      "
                      poster="/assets/film-strip.svg"
                      muted
                    >
                      <source :src="media.url + '#t=0.001'" type="video/mp4" />
                      problem
                    </video>
                  </div>
                </ion-slide>
              </ion-slides>
              <ion-textarea
                :disabled="blockedUser"
                id="textMessageArea"
                v-model="textMessage"
                rows="1"
                auto-grow
                :placeholder="$t('chat.messagePlaceholder')"
                style="max-height: 150px; overflow: hidden; margin-top: 0"
                v-on:keypress.enter="sendMessage()"
                @keydown.enter.shift.exact.prevent="textMessage += '\n'"
              >
              </ion-textarea>
            </div>
            <ion-buttons slot="end">
              <ion-button
                :disabled="blockedUser || sendingMessage"
                mode="ios"
                style="bottom: 0"
                @click="sendMessage()"
              >
                <ion-icon slot="end" :icon="sendOutline"></ion-icon>
                <ion-label>Poslať</ion-label>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-footer>
      </ion-page>
    </ion-split-pane>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, nextTick, computed } from "vue";
import {
  IonSplitPane,
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonIcon,
  IonButton,
  IonButtons,
  IonAvatar,
  IonText,
  IonLabel,
  IonItem,
  IonTextarea,
  IonFooter,
  IonSlides,
  IonSlide,
  IonChip,
  IonSpinner,
  IonFab,
  IonFabButton,
  IonProgressBar,
  actionSheetController,
  modalController,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  alertController,
} from "@ionic/vue";
import {
  chevronBackOutline,
  informationCircleOutline,
  addCircleOutline,
  camera,
  arrowDownOutline,
  trash,
  personOutline,
  sendOutline,
  expandOutline,
} from "ionicons/icons";

import ChatMenu from "@/components/messages/ChatMenu.vue";
import PhotosShowModal from "@/components/PhotosShowModal.vue";
import TheChatProblem from "@/components/messages/TheChatProblem.vue";

import router from "@/router";
import {
  contactInfo,
  chatInfo,
  listOfMessages,
  loadingTheChat,
  errorOccured,
  blockedUser,
  sendMedia,
  mediaUploadProgress,
  sendTextMessage,
  sendingMessage,
  allChatPhotos,
  sendingMedia,
} from "@/components/messages";
import { fbDB } from "@/firebase";
import { user } from "@/auth";
import { loadBaseInfoByUid } from "@/components/profile/show";
import { useI18n } from "vue-i18n";
import { darkModeEnabled } from "@/settings/settings";

import { unblockContact } from "../contacts";

export default defineComponent({
  components: {
    TheChatProblem,
    IonSplitPane,
    ChatMenu,
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonIcon,
    IonSpinner,
    IonButton,
    IonProgressBar,
    IonChip,
    IonSlides,
    IonSlide,
    IonButtons,
    IonAvatar,
    IonFab,
    IonFabButton,
    IonText,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonList,
    IonLabel,
    IonItem,
    IonTextarea,
    IonFooter,
  },
  setup() {
    // TODO
    // const allowedUsers = ["3IzTgLkdV7ZwuKU4bPfEgrsTHGp2", "ByD2w0e6N3YTAHeBuL9Qw7Xa7oO2", "k4rbNyfy5qWB2fAz24iAQfyxaiH3"];
    // if ( !allowedUsers.includes(user.value.uid)) {
    //   router.replace("/laska");
    // }

    const { t } = useI18n({ useScope: "global" });
    const chatId = router.currentRoute.value.params.id;
    const myUid = user.value.uid;
    const menu = ref();
    const noMoreMessages = ref(false);
    errorOccured.value = false;
    mediaUploadProgress.value = false;
    const chatContent = ref();
    const showScrollToBottomButton = ref<boolean>(false);
    const enableScrollEvents = ref<boolean>(false);
    loadingTheChat.value = true;

    const scrollToBottom = () => {
      chatContent.value?.$el.scrollToBottom(400);
    };

    const backToMessages = () => {
      fbDB.ref(`/chats/${chatId}/messages`).off();
      router.replace("/messages");
    };


    const runMessagesListener = async () => {
      /** Turn of automatic listening of messages. */
      fbDB.ref(`/chats/${chatId}/messages`).off();
      fbDB
        .ref(`/chats/${chatId}/messages`)
        .orderByChild("timestamp")
        .startAfter(
          listOfMessages.value[listOfMessages.value.length - 1].timestamp
        )
        .on("child_added", async (messageSnap) => {
          const theChat = Object.assign({}, messageSnap.val());
          theChat["msgId"] = messageSnap.key;
          const messageDate = new Date(theChat["timestamp"]);
          theChat["timeParsed"] = {
            date: messageDate.getDate(),
            year: messageDate.getFullYear(),
            month: messageDate.getMonth() + 1,
            hour: messageDate.getHours(),
            minute: messageDate.getMinutes(),
          };
          listOfMessages.value.push(theChat);
          nextTick().then(() => {
            setTimeout(() => {
              scrollToBottom();
            }, 100);
          });
          fbDB
            .ref(`/users/${user.value.uid}/chats/${chatId}/lastMessage/seen`)
            .set(null)
            .catch((error) => {
              console.log(error);
            });
        });
    };

    /** ########## CHAT INIT ########## */
    const loadMessagesOfChat = async () => {
      loadingTheChat.value = true;
      listOfMessages.value = [];
      allChatPhotos.value = [];

      const chatInfoSnap = await fbDB
        .ref(`users/${user.value.uid}/chats/${chatId}`)
        .once("value");
      if (chatInfoSnap.val() == null) {
        errorOccured.value = true;
        loadingTheChat.value = false;
        return;
      } else {
        chatInfo.value = chatInfoSnap.val();
        chatInfo.value["chatId"] = chatId;
        if (chatInfoSnap.val().blocked) {
          blockedUser.value = true;
        } else {
          blockedUser.value = false;
        }
      }

      const { result, profile } = await loadBaseInfoByUid(
        chatInfoSnap.val().partnerUid
      );
      if (result == "fail" || !profile) {
        errorOccured.value = true;
        loadingTheChat.value = false;
        return;
      }
      contactInfo.value = profile;

      if (blockedUser.value) {
        loadingTheChat.value = false;
        return;
      }

      fbDB
        .ref(`/chats/${chatId}/messages`)
        .orderByChild("timestamp")
        .limitToLast(30)
        .once("value")
        .then((messagesSnap) => {
          for (const [key, value] of Object.entries(messagesSnap.val())) {
            const theChat: any = Object.assign({}, value);
            theChat["msgId"] = key;
            const messageDate = new Date(theChat["timestamp"]);
            theChat["timeParsed"] = {
              date: messageDate.getDate(),
              year: messageDate.getFullYear(),
              month: messageDate.getMonth() + 1,
              hour: messageDate.getHours(),
              minute: messageDate.getMinutes(),
            };
            listOfMessages.value.push(theChat);
          }
          runMessagesListener();
          fbDB
            .ref(`/users/${user.value.uid}/chats/${chatId}/lastMessage/seen`)
            .set(null);
          setTimeout(() => {
            loadingTheChat.value = false;
            setTimeout(() => {
              scrollToBottom();
              enableScrollEvents.value = true;
            }, 700);
          }, 300);
        });

      fbDB.ref(`/chats/${chatId}/media`).once("value", (photosSnap) => {
        if (photosSnap.val() != null) {
          for (const [key, value] of Object.entries(photosSnap.val())) {
            const photo = { msgID: key, ...Object(value) };
            allChatPhotos.value.unshift(photo);
          }
        }

        fbDB
          .ref(`/chats/${chatId}/media`)
          .orderByChild("timestamp")
          .startAfter(
            allChatPhotos.value[0]?.timestamp
          )
          .on("child_added", (photoSnap) => {
            if (photoSnap.val() != null) {
              const photo = {
                msgID: photoSnap.key,
                ...Object(photoSnap.val()),
              };
              allChatPhotos.value.unshift(photo);
            }
          });
      });
    };
    loadMessagesOfChat();

    const loadNextMessages = async (ev: CustomEvent) => {
      const listOfOlderMessages: Array<any> = [];
      fbDB
        .ref(`/chats/${chatId}/messages`)
        .orderByChild("timestamp")
        .limitToLast(20)
        .endBefore(listOfMessages.value[0].timestamp)
        .once("value")
        .then((messagesSnap) => {
          if (messagesSnap.val() == null) {
            noMoreMessages.value = true;
            return;
          }
          const ionicSroll: any = ev.target;
          ionicSroll.complete();
          for (const [key, value] of Object.entries(messagesSnap.val())) {
            const theChat: any = Object.assign({}, value);
            theChat["msgId"] = key;
            const messageDate = new Date(theChat["timestamp"]);
            theChat["timeParsed"] = {
              date: messageDate.getDate(),
              year: messageDate.getFullYear(),
              month: messageDate.getMonth() + 1,
              hour: messageDate.getHours(),
              minute: messageDate.getMinutes(),
            };
            listOfOlderMessages.push(theChat);
          }
          listOfMessages.value = [
            ...listOfOlderMessages,
            ...listOfMessages.value,
          ];
        });
    };

    const unblockContactLocal = async () => {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: t("contacts.unblock"),
        buttons: [
          {
            text: t("buttons.close"),
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: t("buttons.yes"),
            handler: async () => {
              await unblockContact(
                contactInfo.value.uid,
                chatInfo.value.chatId
              );
              blockedUser.value = false;
              loadMessagesOfChat();
            },
          },
        ],
      });
      return alert.present();
    };

    const countOfMessages = computed(() => {
      return listOfMessages.value.length;
    });

    /** ########## TEXT MESSAGE ########## */
    const textMessage = ref<string>("");

    /** ########## PHOTO & VIDEO MESSAGE ########## */
    const mediaData = ref<Array<{ [key: string]: any }>>([]);
    const input1 = ref();
    const mediaForm = ref();
    const selectMedia = () => {
      mediaForm.value.reset();
      input1.value.click();
    };
    const previewPhotoMessage = async (event: any) => {
      if (blockedUser.value) {
        return;
      }
      const previousMedia: Array<{ [key: string]: any }> = mediaData.value;
      const nextMedia: Array<{ [key: string]: any }> = [];
      mediaData.value = [];
  
      await nextTick();

      if (event != null) {
        // URL.revokeObjectURL(img1.value);
        console.log(event.target.files);
        const selectedFiles: [] = event.target.files;
        selectedFiles.forEach((item: any, index: number) => {
          if (item.type.match("image.*")) {
            nextMedia.push({
              type: "image",
              url: URL.createObjectURL(item),
              data: event.target.files[index],
            });
            // nextMedia.push(URL.createObjectURL(item));
          } else {
            nextMedia.push({
              type: "video",
              url: URL.createObjectURL(item),
              data: event.target.files[index],
            });
          }
        });
        // TODO: problem with adding new slides to already initialized slider
        setTimeout(() => {
          mediaData.value = previousMedia.concat(nextMedia);
        }, 200);

        // imageData.value = event.target.files[0];
        // img1.value = URL.createObjectURL(event.target.files[0]);
        // openPhotoMessageModal();
        // console.log(img1);
      }
    };
    const sliderOptionsMediaToSend: any = {
      slidesPerView: "2",
      grabCursor: true,
      spaceBetween: 10,
      breakpoints: {
        350: {
          slidesPerView: 2.5,
          spaceBetween: 5,
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 4,
          spaceBetween: 10,
        },
        800: {
          slidesPerView: 5,
          spaceBetween: 10,
        },
        // when window width is >= 640px
        1000: {
          slidesPerView: 6,
          spaceBetween: 10,
        },
        1200: {
          slidesPerView: 8,
          spaceBetween: 10,
        },
        1400: {
          slidesPerView: 10,
          spaceBetween: 10,
        },
        1600: {
          slidesPerView: 12,
          spaceBetween: 10,
        },
      },
    };
    const showMediaToSendOptions = async (index: number) => {
      const actionSheet = await actionSheetController.create({
        header: t("chat.mediumToSend"),
        cssClass: "",
        buttons: [
          {
            text: t("buttons.show"),
            icon: expandOutline,
            handler: async () => {
              const modal = await modalController.create({
                component: PhotosShowModal,
                cssClass: "photoModalClass",
                componentProps: {
                  photos: mediaData.value,
                  index: index,
                },
              });
              return modal.present();
            },
          },
          {
            text: t("buttons.delete"),
            role: "destructive",
            icon: trash,
            handler: () => {
              mediaData.value.splice(index, 1);
            },
          },
        ],
      });
      await actionSheet.present();

      await actionSheet.onDidDismiss();
    };

    const sendMessage = async () => {
      if (sendingMessage.value != true) {
        sendingMessage.value = true;
        if (mediaData.value.length != 0 && mediaUploadProgress.value != true) {
          mediaUploadProgress.value = true;
          sendMedia(mediaData.value).finally(() => {
            mediaData.value = [];
          });
        }
        await sendTextMessage(textMessage.value);
        textMessage.value = "";
        sendingMessage.value = false;
      }
    };

    const openMediaModal = async (msgID: string) => {
      const index = allChatPhotos.value.findIndex((p) => p.msgID === msgID);
      const photos = allChatPhotos.value;
      const modal = await modalController.create({
        component: PhotosShowModal,
        cssClass: "photoModalClass",
        componentProps: {
          photos: JSON.parse(JSON.stringify(photos)),
          index: JSON.parse(JSON.stringify(index)),
        },
      });
      return modal.present();
    };

    /** Check for difference between dates of two neighboring messages.
     * @returns true  if two messages hasn't been sent in same day.
     */
    const compareMessageTimestamps = (lastDate: any, currentDate: any) => {
      if (lastDate.year == currentDate.year) {
        if (lastDate.month == currentDate.month) {
          if (lastDate.date == currentDate.date) {
            return false;
          }
        }
      }
      return true;
    };

    const pasrseTime = (messageDate: any) => {
      return `${("0" + messageDate.hour.toString()).slice(-2)}:${(
        "0" + messageDate.minute.toString()
      ).slice(-2)}`;
    };

    const checkChatAreaForScroll = (event: any) => {
      const scrollMainArea = event.target.shadowRoot.querySelector("main");
      if (
        scrollMainArea.scrollTop + scrollMainArea.clientHeight + 300 <
        scrollMainArea.scrollHeight
      ) {
        showScrollToBottomButton.value = true;
      } else {
        showScrollToBottomButton.value = false;
      }
    };

    return {
      checkChatAreaForScroll,
      showScrollToBottomButton,
      enableScrollEvents,
      pasrseTime,
      compareMessageTimestamps,
      chatContent,
      unblockContactLocal,
      sendingMessage,
      sendingMedia,
      errorOccured,
      noMoreMessages,
      loadNextMessages,
      countOfMessages,
      scrollToBottom,
      darkModeEnabled,
      loadMessagesOfChat,
      blockedUser,
      openMediaModal,
      mediaForm,
      selectMedia,
      personOutline,
      sendMessage,
      showMediaToSendOptions,
      sliderOptionsMediaToSend,
      mediaUploadProgress,
      menu,
      previewPhotoMessage,
      input1,
      mediaData,
      // imageData,
      // loadData,
      myUid,
      textMessage,
      // sendTextMessageLocal,
      backToMessages,
      contactInfo,
      loadingTheChat,
      listOfMessages,
      chatInfo,
      // messages,
      camera,
      chevronBackOutline,
      arrowDownOutline,
      informationCircleOutline,
      addCircleOutline,
      sendOutline,
    };
  },
});
</script>

<style scoped>
#overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

#text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  max-width: 300px;
  text-align: center;
  font-size: 30px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

ion-item.messageItem {
  --inner-padding-end: 0.5rem;
  --padding-start: 0.5rem;
}

.message {
  margin-bottom: 0.5rem;
  border-radius: 20px;
  padding: 8px 15px;
}

.messageMine {
  background-color: #888;
  color: white;
  /* background: linear-gradient(to bottom, #00d0ea 0%, #2225d1 100%); */
  /* background-attachment: fixed;  Problem on mobile*/
}

.messageYours {
  background-color: #da1839;
  color: white;
}

#textMessageArea {
  padding-left: 10px;
  border: 1px solid #eee;
  border-radius: 20px;
}

ion-item {
  --background: transparent;
}
</style>