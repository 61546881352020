
import {
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  modalController,
} from "@ionic/vue";
import {
  personOutline,
  schoolOutline,
  locationOutline,
  briefcaseOutline,
  homeOutline,
  chevronBackOutline,
} from "ionicons/icons";
import { ref, defineComponent } from "vue";
import { loadProfileByUid } from "@/components/profile/show";

import ProfileHeder from "@/components/profile/show/ProfileHeader.vue";
import ProfileBody from "@/components/profile/show/ProfileBodyC.vue";
import ProfileSkeleton from "@/components/profile/show/ProfileSkeleton.vue";

export default defineComponent({
  name: "ProfileModal",
  components: {
    ProfileBody,
    ProfileHeder,
    ProfileSkeleton,
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonButton,
    IonButtons,
  },
  props: {
    uid: String,
  },
  setup(props: any) {
    const closeModal = async () => {
      const modal = await modalController.getTop();
      if (modal) {
        modal.dismiss();
      }
    };

    const profile = ref<object | null>({});
    const photos = ref<string[]>([]);
    const loadingMyProfile = ref(true);
    const errorOccured = ref(false);
    const loadMyProfileToShow = async () => {
      loadingMyProfile.value = true;
      try {
        const result = await loadProfileByUid(props.uid);
        if (result != null) {
          profile.value = result.profile;
          photos.value = result.allPhotos;
        } else {
          profile.value = null;
        }
      } catch (err) {
        errorOccured.value = true;
      } finally {
        loadingMyProfile.value = false;
      }
    };
    loadMyProfileToShow();

    return {
      closeModal,
      // REFERENCES
      // myProfile,
      loadingMyProfile,
      errorOccured,
      photos,
      profile,

      // FUNCTIONS

      // ICONS
      personOutline,
      schoolOutline,
      locationOutline,
      briefcaseOutline,
      homeOutline,
      chevronBackOutline,
    };
  },
});
