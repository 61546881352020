
import { defineComponent, ref } from "vue";
import { IonPage, IonCard, IonImg, IonButton } from "@ionic/vue";
import router from "@/router";
import { reportProblem } from "@/report";

export default defineComponent({
  components: {
    IonPage,
    IonCard,
    IonImg,
    IonButton,
  },
  setup() {
    const backToMessages = () => {
      router.replace("/messages");
    };
    const alreadyReported = ref(false);

    const reportChatProblem = () => {
      if (!alreadyReported.value) {
        reportProblem({
          chatId: router.currentRoute.value.params.id,
          type: "chat",
        });
      }
      alreadyReported.value = true;
    };

    return { backToMessages, reportChatProblem, alreadyReported };
  },
});
